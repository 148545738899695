*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  height: 100%; // IMPORTANT: This is what Angular Material adds when initialised. Do not remove
  background: var(--next-off-white-50);
}

body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;

  margin: 0;
  padding: 0;
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

input,
button,
textarea,
select {
  font: inherit;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}
